<template>
  <IntroPage />
</template>

<script>
import IntroPage from './components/IntroPage.vue'

export default {
  name: 'App',
  components: {
    IntroPage
  }
}
</script>
